// 404 Page

// Libraries
import React from 'react'

// Elements
import { Box } from '@/components/elements'

// Theme
import theme from '../../config/theme'

const fourofourPage = () => {
  return <Box>404</Box>
}

export default fourofourPage
